import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import DragHandle from './component-drag-handle';
import { makeFieldCopy, makeGroupCopy } from '../../FormBuilder/utils';

import Stack from '@mui/material/Stack';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import Tooltip from '@mui/material/Tooltip';

import { setCopy, deleteRelatedDependancies } from '../slice';
import CopyIcon from '../../../assets/customIcons/copyIcon';
import { checkOverflow } from '../../../utils/utilFunctions';
import { isBefore } from '../../../utils/dateUtils';

const HeaderBar = (props) => {
	const dispatch = useDispatch();
	const reduxFormSchema = useSelector((state) => state.form.formSchema);
	const date_finalized = useSelector((state) => state.form.applicationProcess?.date_finalized);
	const style = { width: '30px', height: '30px' };
	const noEdit = ['TwoColumnRow', 'ThreeColumnRow', 'MultiColumnRow'];
	const [openTooltip, setOpenTooltip] = useState(0);

	const removeAllActions = props.data.custom_options?.includes('not-editable');

	const isCreatedBeforeFinalized = isBefore(props.data.created_at, date_finalized);

	const editDisabled =
		isCreatedBeforeFinalized ||
		(props.data.element !== 'LineBreak' &&
			props.data.key !== 'MUI_GroupingEnd' &&
			noEdit?.includes(props.data.element));

	const deleteDisabled = props.data.created_at != null && date_finalized != null;

	const copyDisabled = noEdit?.includes(props.data.element) && props.data.key !== 'MUI_GroupingEnd';
	return (
		<div className={`toolbar-header`}>
			<span className="SortableItem-element-type">
				<Tooltip
					open={openTooltip === `${props?.data?.field_name}`}
					onOpen={(e) => {
						checkOverflow(e) && setOpenTooltip(props?.data?.field_name);
					}}
					onClose={() => setOpenTooltip(0)}
					arrow
					placement="top"
					title={props.data.text}
				>
					<p>{props.data.text}</p>
				</Tooltip>
			</span>

			<div className="toolbar-header-buttons">
				<Stack direction="row" spacing={0}>
					<IconButton
						data-testid={'formBuilder-editIcon'}
						style={style}
						aria-label="edit"
						onClick={props.editModeOn.bind(props.parent, props.data)}
						disabled={removeAllActions || editDisabled}
					>
						<EditIcon
							sx={{ width: 'auto', height: '15px' }}
							color={removeAllActions || editDisabled ? 'action' : 'primary'}
						/>
					</IconButton>

					<IconButton
						style={style}
						data-testid={'formBuilder-copyButton'}
						aria-label="copy"
						disabled={removeAllActions || copyDisabled}
						onClick={() => {
							const copy =
								props?.data?.key === 'MUI_GroupingStart'
									? makeGroupCopy(props.data, reduxFormSchema)
									: makeFieldCopy(props.data);

							dispatch(setCopy(copy));
						}}
					>
						<CopyIcon isActive={!removeAllActions && !copyDisabled} width={'15px'} />
					</IconButton>

					<IconButton
						data-testid={'formBuilder-deleteButton'}
						style={style}
						aria-label="delete"
						color="error"
						disabled={removeAllActions || deleteDisabled}
						onClick={() => {
							dispatch(deleteRelatedDependancies([reduxFormSchema, props.data]));
							props.onDestroy(props.data);
						}}
					>
						<DeleteIcon
							sx={{ width: 'auto', height: '15px' }}
							color={removeAllActions || deleteDisabled ? 'action' : 'error'}
						/>
					</IconButton>
					<IconButton
						style={{
							...style,
							pointerEvents: removeAllActions && 'none',
							cursor: removeAllActions ? 'default' : 'move'
						}}
						aria-label="drag"
					>
						<DragHandle
							disabled={removeAllActions}
							data={props.data}
							index={props.index}
							onDestroy={props.onDestroy}
							setAsChild={props.setAsChild}
						/>
					</IconButton>
				</Stack>
			</div>
		</div>
	);
};

export default HeaderBar;

import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Helmet } from 'react-helmet';
import StyledWrapper from './style';

import {
	Alert,
	Collapse,
	IconButton,
	Box,
	Stepper,
	Step,
	StepLabel,
	Button,
	Typography,
	CircularProgress
} from '@mui/material';
import { Close as CloseIcon, Edit as EditIcon } from '@mui/icons-material';
import LoadingButton from '@mui/lab/LoadingButton';

import { ReactFormGenerator } from '../FormBuilderLibrary';
import { checkAllConditions } from '../../utils/conditionalLogic';
import {
	usePostFormValuesMutation,
	useUpdateFormStatusMutation
} from '../../services/endpoints/formBuilderEndpoints';
import { Instructions } from '../FormBuilder/customComponents/Instructions';
import { SimulateSaveButton } from '../SimulateSaveButton/SimulateSaveButton';
import { setFormData } from '../FormBuilderLibrary/slice';
import { useSaveCoreInfoMutation } from '../../services/endpoints/programsEndpoints';
import { usePermissions } from '../../hooks/usePermissions';
import { disabledStatuses } from '../../constants/statuses';
import { areRequiredFieldsCompleted, exportFormToPDF } from '../../utils/utilFunctions';
import { getLocalAuth } from '../../utils/environmentUtils';

export default function MultiFormViewer(props) {
	const dispatch = useDispatch();

	const path = window.location.pathname;
	const pathSegments = path.split('/');

	const [alertBalloon, setAlertBalloon] = useState({ isOpen: false });
	const [activeStep, setActiveStep] = useState(0);
	const [skipped, setSkipped] = useState(new Set());
	const [formSchema, setFormSchema] = useState();
	const [formAnswers, setFormAnswers] = useState([]);
	const [steps, setSteps] = useState(['']);
	const [loadingCancel, setLoadingCancel] = useState(true);
	const [buttonLoading, setButtonLoading] = useState(false);

	const appID = pathSegments.find((segment) => segment.includes('api-'))?.replace('api-', '');
	const accountSource = useSelector((state) => state?.login?.accountSource);
	const apiToken = getLocalAuth(accountSource)?.access_token;
	const reduxFormSchema = useSelector((state) => state?.form?.formSchema);
	const allDependancies = useSelector((state) => state?.form?.dependancies);
	const answers = useSelector((state) => state?.form?.form);
	const formMetaInfo = useSelector((state) => state.form.formMetaInfo);
	const readOnly = props.readOnly ?? false;

	const { hasEditPermissions, userAccountTypeID } = usePermissions();

	const [updateFormStatus] = useUpdateFormStatusMutation();
	const [postFormValues] = usePostFormValuesMutation();
	const [saveCoreInfo] = useSaveCoreInfoMutation();

	useEffect(() => {
		if (props.form) {
			setFormSchema(props.form);
			setSteps(props.form?.map(() => ''));
		} else if (reduxFormSchema[0] !== null) {
			let schema = reduxFormSchema?.filter((e) => {
				return e !== undefined;
			});
			setFormSchema(schema);
			setSteps(schema?.map(() => ''));
		}
	}, [props.form, reduxFormSchema]);

	useEffect(() => {
		if (props.formAnswers) {
			setFormAnswers(formAnswers);
			checkAllConditions(allDependancies, props.formAnswers);
		} else {
			setFormAnswers(answers);
			checkAllConditions(allDependancies, answers);
		}
		setTimeout(function () {
			setLoadingCancel(false);
		}, 1000);
	}, [activeStep, answers, props.formAnswers]);

	useEffect(() => {
		checkAllConditions(allDependancies, answers);
	});

	const isStepSkipped = (step) => {
		return skipped.has(step);
	};

	const handleNext = () => {
		let newSkipped = skipped;
		if (isStepSkipped(activeStep)) {
			newSkipped = new Set(newSkipped.values());
			newSkipped.delete(activeStep);
		}

		setActiveStep((prevActiveStep) => prevActiveStep + 1);
		setSkipped(newSkipped);
	};

	const handleBack = () => {
		setActiveStep((prevActiveStep) => prevActiveStep - 1);
	};

	const handleReset = () => {
		setActiveStep(0);
	};

	const handleAlert = (message, status) => {
		setButtonLoading(false);
		setAlertBalloon({ isOpen: true, message, status });
		setTimeout(() => {
			setAlertBalloon((prevAlertBalloon) => ({
				...prevAlertBalloon,
				isOpen: false
			}));
		}, 3000);
	};

	const handleFormSubmit = async (data) => {
		setButtonLoading('save');
		if (props.isSimulating) {
			handleAlert('Form Saved Successfully!', 'success');
			return;
		}

		if (props.disableFooterButtons && !props.hideActionButtons) {
			handleAlert('Form Saved Successfully!', 'success');
		} else {
			let noNullAnswers = data.filter((e) => e !== null && e !== undefined);
			let options = {
				formID: props.formID?.split('-')?.[1],
				payload: noNullAnswers,
				apiToken: apiToken,
				application_id: appID
			};

			const formSchema = props.apiFormData?.form?.form_schema;
			const coreInfoElements = formSchema?.filter(
				(e) =>
					e?.custom_options?.includes('project-title') || e?.custom_options?.includes('org-name')
			);

			// SAVE CORE INFO
			if (props.apiFormData?.form?.purpose === 'Core Information') {
				const payload = {};
				coreInfoElements?.map((e) => {
					const elementAnswer = noNullAnswers?.find((x) => e?.field_name === x?.name);
					if (e?.custom_options?.includes('org-name')) {
						payload['org_name'] = elementAnswer?.value || '';
					}
					if (e?.custom_options?.includes('project-title')) {
						payload['project_title'] = elementAnswer?.value || '';
					}
				});

				await saveCoreInfo({
					...options,
					payload: payload
				})
					.unwrap()
					.then(() => {
						postFormValues(options)
							.unwrap()
							.then(() => {
								handleAlert('Form Saved Successfully!', 'success');
								props.setReadOnly(true);
							})
							.catch(() => handleAlert('Form Saving Failed!', 'error'));
					})
					.catch(() => {
						handleAlert('Form Saving Failed!', 'error');
						return;
					});
			} else {
				postFormValues(options)
					.unwrap()
					.then(() => {
						handleAlert('Form Saved Successfully!', 'success');
						props.setReadOnly(true);
					})
					.catch(() => handleAlert('Form Saving Failed!', 'error'));
			}
		}
	};

	const handleCancel = () => {
		setLoadingCancel(true);
		dispatch(setFormData([1, props.apiFormData?.field_values] || []));
		props.setReadOnly(true);
		setTimeout(function () {
			setLoadingCancel(false);
		}, 1000);
	};

	const handleReopenForm = () => {
		setButtonLoading('reopen');
		let id = props.formID?.split('-')?.[1];
		const options = {
			id: id,
			status: 'Draft (returned)',
			apiToken: apiToken
		};
		updateFormStatus(options)
			.unwrap()
			.then(() => {
				setButtonLoading(false);
				handleAlert('Status Updated Successfully!', 'success');
			})
			.catch(() => {
				setButtonLoading(false);
				handleAlert('Status Update Failed!', 'error');
			});
	};

	const handleCompleteForm = () => {
		const validForm = areRequiredFieldsCompleted(props.form[0], props.formAnswers[1]);
		if (validForm) {
			setButtonLoading('complete');
			let id = props.formID?.split('-')?.[1];
			const options = {
				id,
				status: 'Completed',
				apiToken
			};
			updateFormStatus(options)
				.unwrap()
				.then(() => {
					setButtonLoading(false);
					handleAlert('Status Updated Successfully!', 'success');
				})
				.catch(() => {
					setButtonLoading(false);
					handleAlert('Status Update Failed!', 'error');
				});
		} else {
			setButtonLoading(false);
			handleAlert('This form contains errors.', 'error');
		}
	};

	return (
		<Box sx={{ width: '100%' }}>
			<Helmet>
				<link rel="stylesheet" href="https://use.fontawesome.com/releases/v5.13.0/css/all.css" />
				<link
					rel="stylesheet"
					href="https://stackpath.bootstrapcdn.com/bootstrap/4.4.1/css/bootstrap.min.css"
					integrity="sha384-Vkoo8x4CGsO3+Hhxv8T/Q5PaXtkKtu6ug5TOeNV6gBiFeWPGFN9MuhOf23Q9Ifjh"
					crossOrigin="anonymous"
				/>
			</Helmet>
			<Stepper activeStep={activeStep}>
				{steps.map((label) => {
					const stepProps = {};
					const labelProps = {};

					return steps?.length > 1 ? (
						<Step key={label} {...stepProps}>
							<StepLabel {...labelProps}></StepLabel>
						</Step>
					) : null;
				})}
			</Stepper>

			<Collapse in={alertBalloon.isOpen}>
				<Alert
					severity={alertBalloon?.status}
					action={
						<IconButton
							aria-label="close"
							color={alertBalloon?.status}
							size="small"
							onClick={() => {
								setAlertBalloon({ isOpen: false });
							}}
						>
							<CloseIcon fontSize="inherit" />
						</IconButton>
					}
					sx={{ mb: 2, mt: 2 }}
				>
					{alertBalloon?.message}
				</Alert>
			</Collapse>

			{activeStep === steps.length ? (
				<>
					<Typography sx={{ mt: 2, mb: 1 }}>All steps completed - you&apos;re finished</Typography>
					<Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
						<Box sx={{ flex: '1 1 auto' }} />
						<Button onClick={handleReset}>Reset</Button>
					</Box>
				</>
			) : (
				<>
					{props.disableFooterButtons && !props.hideActionButtons && (
						<SimulateSaveButton
							readOnly={readOnly}
							setReadOnly={props.setReadOnly}
							marginBottom={props.marginBottom}
							setOuterLoadingCancel={setLoadingCancel}
						/>
					)}

					{(formMetaInfo?.instructions || props.formInstructions) && !props.hideInstructions && (
						<Instructions
							data={{ content: props.formInstructions || formMetaInfo?.instructions }}
						/>
					)}

					<StyledWrapper.LastEditedActions>
						{hasEditPermissions &&
							props.status?.toLowerCase()?.includes('completed') &&
							!disabledStatuses?.includes(props.application_status) && (
								<LoadingButton
									loading={buttonLoading === 'reopen'}
									variant="contained"
									component="label"
									data-testid={'individualapp-reopen-button'}
									onClick={handleReopenForm}
								>
									Reopen
								</LoadingButton>
							)}
						{!props.disableActionButtons &&
							(hasEditPermissions || !userAccountTypeID) &&
							(props.readOnly ? (
								<>
									<Button
										variant="contained"
										onClick={() =>
											exportFormToPDF(props.apiFormData, props.application_status, appID)
										}
									>
										Export to PDF
									</Button>

									<Button
										disabled={props.apiFormData?.status === 'Completed'}
										data-testid={'individualapp-edit-button'}
										variant="outlined"
										onClick={() => {
											props.setReadOnly(false);
										}}
										startIcon={<EditIcon />}
									>
										{props.formID?.includes('afi') ? 'Edit Application Form' : 'Edit'}
									</Button>

									{props.status?.toLowerCase()?.includes('draft') && (
										<LoadingButton
											loading={buttonLoading === 'complete'}
											variant="contained"
											component="label"
											data-testid={'individualapp-save-button'}
											onClick={handleCompleteForm}
										>
											Complete
										</LoadingButton>
									)}
								</>
							) : (
								<>
									<LoadingButton
										loading={buttonLoading === 'save'}
										variant="contained"
										component="label"
										data-testid={'individualapp-save-button'}
										onClick={() => {
											const element = document.getElementById(`${props.formID}-saveButton`);
											element.click();
										}}
									>
										Save
									</LoadingButton>
									<Button
										variant="outlined"
										onClick={handleCancel}
										data-testid={'individualapp-cancel-button'}
									>
										Cancel
									</Button>
								</>
							))}
					</StyledWrapper.LastEditedActions>

					{loadingCancel ? (
						<Box
							sx={{
								display: 'flex',
								width: '100%',
								height: '100%',
								alignItems: 'center',
								justifyContent: 'center'
							}}
						>
							<CircularProgress />
						</Box>
					) : (
						<ReactFormGenerator
							download_path=""
							disableFooterButtons={props.disableFooterButtons}
							formID={props.formID || 'testing-form'}
							back_action={''}
							back_name="Back"
							answer_data={
								props.formAnswers ? props.formAnswers[activeStep + 1] : formAnswers[activeStep + 1]
							}
							action_name="Save"
							hide_actions={true}
							form_action="/"
							form_method="POST"
							onSubmit={handleFormSubmit}
							NextPage={() => handleNext()}
							GoBack={() => handleBack()}
							activeStep={activeStep}
							totalSteps={steps}
							variables={[]}
							formIndex={activeStep + 1}
							read_only={readOnly}
							data={formSchema ? formSchema[activeStep] : []}
							funded={props.funded}
						/>
					)}
				</>
			)}
		</Box>
	);
}

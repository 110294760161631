import { useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import StyledWrapper from './style';
import ApplicationFormContent from './subPages/ApplicationFormContent';
import ReviewStageOverview from '../IndividualReview/subPages/ReviewStageOverview';
import ApplicationOverview from './subPages/ApplicationOverview';
import ReviewDetails from '../IndividualReview/subPages/ReviewDetails';
import SubmissionStageOverview from './subPages/SubmissionStageOverview';

import { ItemPageTitle } from '../../components/ItemPageTitle';
import { VerticalNavigation } from '../../components/VerticalNavigation';
import { TabPanel } from '../../components/TabPanel';
import ReviewFormContainer from '../IndividualReview/subPages/ReviewFormContainer';
import { getApplicationPageTitle } from '../../utils/utilFunctions';
import { useGetAppProcessNav } from '../../hooks/useGetAppProcessNav';
import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';
import Collapse from '@mui/material/Collapse';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import ProjectTeam from './subPages/ProjectTeam';
import NoApplicantProtection from '../../components/ProtectedRoute/NoApplicantProtection';
import CircularProgress from '@mui/material/CircularProgress';
import Stack from '@mui/material/Stack';

const IndividualApplication = ({ appId }) => {
	const location = useLocation();
	let { programID } = useParams();
	const userAccountTypeID = useSelector((state) => state.login?.user?.userAccountTypeID);
	const isReviewer = useSelector((state) => state.login?.user?.isReviewer);
	programID = programID?.replace('p-', '');
	const pathArray = location?.pathname?.split('/');

	const [value, setValue] = useState(0);
	const [chosenView, setChosenView] = useState(<></>);
	const [alertBalloon, setAlertBalloon] = useState(false);

	const { data: navData, isLoading } = useGetAppProcessNav(appId);

	const innerTabs = ['compare-reviews', 'layout', 'details', 'overview', 'content'];
	const currentPage = pathArray[pathArray.length - 1];
	let subPage = innerTabs.includes(currentPage) ? pathArray[pathArray.length - 2] : currentPage;

	const handleAlert = (message, status) => {
		setAlertBalloon({ isOpen: true, message, status });
		setTimeout(() => {
			setAlertBalloon(false);
		}, 2000);
	};

	const overviewPageSelect = useCallback(() => {
		if (subPage?.includes('esi-')) {
			return userAccountTypeID ? (
				<ReviewStageOverview appId={appId} subPage={subPage} handleAlert={handleAlert} />
			) : (
				<NoApplicantProtection />
			);
		}
		if (subPage?.includes('rfi-')) {
			return isReviewer || userAccountTypeID ? (
				<ReviewDetails formID={subPage} navData={navData} />
			) : (
				<NoApplicantProtection />
			);
		}
		if (subPage?.includes('afi-')) {
			return <ApplicationFormContent formID={subPage} />;
		}
		if (subPage?.includes('rfm-')) {
			return userAccountTypeID ? (
				<ReviewFormContainer appId={appId} handleAlert={handleAlert} />
			) : (
				<NoApplicantProtection />
			);
		}
		if (subPage?.includes('ssi-')) {
			return <SubmissionStageOverview appId={appId} subPage={subPage} handleAlert={handleAlert} />;
		}
		if (subPage?.includes('api-')) {
			return <ApplicationOverview appId={appId} handleAlert={handleAlert} />;
		}
		if (subPage?.includes('project%20team')) {
			return <ProjectTeam />;
		} else {
			return <></>;
		}
	}, [appId, isReviewer, navData, subPage, userAccountTypeID]);

	const selectView = useCallback(
		(view) => {
			switch (view) {
				case 0:
					return overviewPageSelect();
				case 1:
					if (appId?.includes('arr-') || appId?.includes('rvf-')) {
						return <ReviewDetails />;
					} else {
						return <ApplicationFormContent />;
					}
				default:
					return <></>;
			}
		},
		[appId, overviewPageSelect]
	);

	useEffect(() => {
		setChosenView(selectView(value));
	}, [value, location, selectView]);

	return (
		<StyledWrapper.OutermostContainer id={'IndividualApplication-outermostContainer'}>
			<ItemPageTitle
				text={getApplicationPageTitle({
					appId,
					title: 'Test Title',
					applicationStageID: appId,
					reviewer: 'test reviewer'
				})}
			/>
			<StyledWrapper.InnerContainer>
				{navData && !isLoading && (
					<VerticalNavigation
						customInnerWidth={'290px'}
						data={navData}
						baseURL={`/programs/p-${programID}/applications`}
					/>
				)}
				<StyledWrapper.FormContainer>
					<Box sx={{ width: '100%' }}>
						<Collapse in={alertBalloon}>
							<Alert
								severity={alertBalloon?.status}
								action={
									<IconButton
										aria-label="close"
										color={alertBalloon?.status}
										size="small"
										onClick={() => {
											setAlertBalloon(false);
										}}
									>
										<CloseIcon fontSize="inherit" />
									</IconButton>
								}
								sx={{ mb: 2, mt: 2 }}
							>
								{alertBalloon?.message}
							</Alert>
						</Collapse>

						<TabPanel data-testid={`individualReview-tab-panel`} value={value} index={value}>
							{isLoading ? (
								<Stack justifyContent="center" alignItems="center" sx={{ width: 1, height: 1 }}>
									<CircularProgress />
								</Stack>
							) : (
								chosenView
							)}
						</TabPanel>
					</Box>
				</StyledWrapper.FormContainer>
			</StyledWrapper.InnerContainer>
		</StyledWrapper.OutermostContainer>
	);
};

export default IndividualApplication;
